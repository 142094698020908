<template>
  <div>
    <div class="content py-3">
      <div class="row">
        <div class="col-12" v-for="zahlungsart in zahlungsarten" :key="zahlungsart.id">
          <div class="box box-auswahl payment-method" v-bind:class="{
            'box-auswahl-selected': bestellung.zahlungsartid === zahlungsart.id,
          }" @click="changeZahlungsart(zahlungsart.id)">
            <h4>{{ zahlungsart.titel }}</h4>
            <p><span v-html="zahlungsart.beschreibung"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="box-footer d-flex justify-content-between flex-lg-row">
      <div class="left">
        <router-link :to="{ name: 'BestellungLiefermethode' }" class="btn btn-outline-secondary"><i
            class="fa fa-chevron-left"></i> Zur&uuml;ck</router-link>
      </div>
      <div class="right">
        <router-link :to="{ name: 'BestellungUebersicht' }" class="btn btn-primary">
          Weiter <i class="fa fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {
  serviceLoadBestellungZahlungsart,
  serviceSaveZahlungsart,
} from "@/api/bestellung.api.js";

export default {
  name: "ShopCheckoutZahlmethode",
  mounted() {
    this.$store.dispatch("providingHashvalues").then(async r => {
      if (r) {
        serviceLoadBestellungZahlungsart(this.$store.getters.getWarenkorbHash, this.$store.getters.getKundenHash).then(response => {
          if (response.data.successful) {
            this.zulaessigeZahlungsarten = response.data.zulaessigeZahlungsarten;
            this.bestellung.zahlungsartid = response.data.bestellung.zahlungsartid;
            this.$store.commit("bestellung/setZahlungsartId", this.bestellung.zahlungsartid);
          }
        })
      }
    });
  },
  methods: {
    async changeZahlungsart(zahlungsartidNeu) {
      this.bestellung.zahlungsartid = zahlungsartidNeu;
      try {
        await serviceSaveZahlungsart(
          this.bestellung.zahlungsartid,
          this.$store.getters.getWarenkorbHash,
          this.$store.getters.getKundenHash
        );
        this.$store.commit("bestellung/setZahlungsartId", this.bestellung.zahlungsartid);
      } catch (error) {
        console.log(
          "ShopCheckoutZahlmethode changeVersandart ERROR: " + error
        );
      }
    },
  },
  data: function () {
    return {
      zulaessigeZahlungsarten: [],
      bestellung: {
        zahlungsartid: null,
      },
    };
  },
  computed: {
    zahlungsarten() {
      return this.$store.getters["keyparameter/getZahlungsartenErlaubt"](this.zulaessigeZahlungsarten);
    }
  }
};
</script>
<style scoped>
.nav-link {
  padding: 0;
}

.clickicon {
  cursor: pointer;
}

.warenkorb-summe {
  padding: 3px 12px;
}

.warenkorb-versandkosten {
  border-top: 0px solid white;
  padding: 3px 12px;
}

.warenkorb-gesamtpreis {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px double #dee2e6;
  padding: 3px 12px;
}

.box-selected {
  border: solid 2px #2c3e50;
  box-shadow: 0 1px 5px #2c3e50;
}
</style>